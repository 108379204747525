/* global woopra */

import React from 'react';
import ReactDOM, { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { withRouter } from "react-router-dom";

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";

import StarRating from './components/StarRating';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Reviews from './Reviews';
import Stars from './Stars';
import ClaimDialog from './ClaimDialog';
import SendMessageDialog from './SendMessageDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EmailIcon from '@material-ui/icons/Email';
import RecordInactive from './RecordInactive';
import backgroundImageAbout from './assets/carriesfind-profile-placeholder-1.png';
import selectedStar from './assets/Review-star-selected.png';
import unselectedStar from './assets/Review-star-unselected.png';
import './Carriers.css';
import ReactMapGL, {Marker} from 'react-map-gl';
import Geocoder from 'react-mapbox-gl-geocoder';
import locationPin from './assets/Location-pin-1-selected.png';
import writeReviewStar from './assets/write-review-star.png';
import editIcon from './assets/edit-icon@2x.png';
import {API_HOST_NAME} from './Environment.js';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import Messages from './Messages';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { APP_HOST_NAME } from './Environment.js';
import Adds from './Adds.js';


const mapStyle = {
  width: '100%',
  height: '400px'
}

const params = {
    country: "us"
}


const mapboxApiKey ='pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

class Carrier extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      showSendMessageDialog: false,
      showReviewAdd: false,
      showClaimDialog: false,
      review_page: 0,
      isEditable: false,
      viewport: {
        zoom: 8
      },
      dot_number_input: null,
    }
  }



  showReviewDialog = () => {
    if(!this.props.showSignupDialog(null, () => {
      this.setState(() => { return { showReviewAdd: true }});
    })) {
      this.setState(() => { return { showReviewAdd: true }});
    }
  }

  hideReviewDialog = () => {
    this.setState(() => { return { showReviewAdd: false }});
  }

  isLetter = (str) => {
    return str.length === 1 && str.match(/[a-z]/i);
  }
  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  displayName = (name) => {
    if (!name) {
      return;
    }
    var newName = []
    name.split(" ").forEach((part) => {
      var format = false;
      for (var i = 0; i < part.length - 1 ; i++) {
        if (part.toLowerCase() == 'llc') {
          part = 'LLC';
          break;
        }
        const c = part.charAt(i);
        const d = part.charAt(i + 1);
        if (this.isLetter(c) && this.isLetter(d)) {
          format = true;
          break
        }
      }
      if (format) {
        newName.push(this.capitalize(part.toLowerCase()))
      } else {
        newName.push(part)
      }
    })

    return newName.join(" ")
  }

  componentDidMount() {
    var { dot_number, slug } = this.props.match.params;
    if (!dot_number) {
      dot_number = this.props.dot_number
      slug = this.props.slug
    }
    this.getCarrierData(dot_number, slug, this.state.review_page);
    woopra.track('pv', {
      title: `dot_number_${dot_number}`,
      url: window.location.href
    })
  }

  getCarrierData = (dot_number, slug, review_page) => {
    fetch(`${API_HOST_NAME()}/carriers/${dot_number}/business/${slug}?review_page=${review_page}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState((state, props) => {
          debugger
          return json
        }, () => {
          this.setState(() => {
            const favorite = this.isFavorite()
            return {
              isEditable: localStorage.getItem("uid") === this.state.user?.uid,
              favorite: favorite,
              viewport: {
                longitude: this.state.address?.longitude ?? -95,
                latitude: this.state.address?.latitude ?? 37,
                zoom: 10
              }
            }

            })
        });
      });
  }

  isFavorite = () => {
    var found = false;
    if (this.state.favorite_carrier_ids) {
      this.state.favorite_carrier_ids.forEach((id) => {
        if(id === this.state.safer_page.carrier_id) {
          found = true
        }
      })
    }
    return found
  }

  setRating = (rating) => {
    this.setState(() => {
      return {stars: rating}
    })
  }

  setNextPage = (page) => {
    this.setState(() => { return {page: page}}, this.getCarrierData() )
  }


  showClaimDialog = () => {
    if(!this.props.showSignupDialog(null, () => {
      this.setState(() => { return { showClaimDialog: true }});
    })) {
      this.setState(() => {return {showClaimDialog: true}})
    }
  }

  hideClaimDialog = () => {
    this.setState(() => {return {showClaimDialog: false}})
  }


  showSendMessageDialog = () => {
    this.setState(() => {return {showSendMessageDialog: true}})
  }
  hideSendMessageDialog = () => {
    this.setState(() => {return {showSendMessageDialog: false}})
  }
  // edit info dialo

  showEditInfo = () => {
    this.setState(() => {return {showEditInfo: true}})
  }

  hideEditInfo = () => {
    this.setState(() => {return {showEditInfo: false}})
  }


  searchArea = (e) => {
    this.props.onUpdateFilters("id_numbers", "name", null, false)
    this.props.onUpdateFilters("id_numbers", "dot_number", this.state.dot_number_input)
    this.props.history.push({pathname: '/'})
  }

  favorite = (e) => {
    if(!this.props.showSignupDialog()) {
      this.setState(() => { return { favorite: e }},
      () => {
        fetch(`${API_HOST_NAME()}/carriers/${this.state.safer_page.dot_number}/favorite/${e}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json',
          'uid': localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          'expiry': localStorage.getItem('expiry'),
          'client': localStorage.getItem('client')
          }
        }).then(response => response.json())
          .then(json => {
            if (!json.success) {
              this.setState( (state, props) => {
                return {favorite: !e}
              });
            }
            woopra.track('favorite', {
              dot_number: `dot_number_${this.state.safer_page.dot_number}`,
            })

          });
      })
    }
  }


  renderCarrierFirstCard(aboutSection, renderFirstCardOnly) {
    return (
      <div className="card first-card" >
        <div className="backgroundImage">
          {this.state.favorite ?
            <Tooltip onClick={() => { this.favorite(false) }} title="Remove from Favorites" style={{ float: 'right', height: '40px' }}>
              <IconButton aria-label="remove favorite">
                <StarIcon style={{ fill: 'yellow' }} />
              </IconButton>
            </Tooltip>
            :
            <Tooltip onClick={() => { this.favorite(true) }} title="Add to Favorites" style={{ float: 'right', height: '40px' }}>
              <IconButton aria-label="favorite">
                <StarBorderIcon style={{ fill: 'white' }} />
              </IconButton>
            </Tooltip>
          }
        


        <div className="bottomCard">
          <Stars numberOfStars={this.state.stars || 3} />
            <span onClick={() => {
              if (renderFirstCardOnly) {
                window.open(`${APP_HOST_NAME()}/carriers/${this.state.safer_page?.dot_number}/name/${this.state.safer_page?.slug}`, '_blank').focus();
              } else {
                document.getElementById('review-section').scrollIntoView()
              }
              
          }} style={{color: 'white', verticalAlign: 'super'}}> {this.state.reviews?.length || 0} Reviews </span>
            { !renderFirstCardOnly && <Tooltip onClick={() => { document.getElementById('review-section').scrollIntoView() }}
              title="Skip to Reviews" style={{ width: '20px', height: '20px', verticalAlign: 'baseline' }}>
              <IconButton aria-label="reviews">
                <ArrowDownwardIcon style={{ fill: 'white', width: '15px', height: '20px' }} />
              </IconButton>
            </Tooltip>}
          <h1 style={{color: 'white'}}> {this.displayName(this.state?.name)} </h1>
          {this.state?.safer_page?.dba && <h2 style={{color: 'white'}}> {this.displayName(this.state?.safer_page?.dba)}</h2>}
          </div>
        </div>
          <div style={{marginLeft: '30px'}}>
            <h2> About{ this.state.isEditable && !renderFirstCardOnly && (<span style={{float: 'right', marginRight: "30px", backgroundColor: 'rgb(26, 113, 216)', borderRadius: '3px'}} onClick={this.showEditInfo}><img width="20px" src={editIcon}/></span>) }</h2>
            <p> { this.state.description ?
              <p> {this.state.description} </p>
              :
              <p> {aboutSection}

               </p>
            }</p>


            <h3> Carrier Operation </h3>
            <div style={{margin: '10px'}}>
            <ul>
            {
              this.state?.safer_page?.carrier_operation.map(
                (label) => <li className="tag">{label}</li>)
            }
            </ul>
            </div>

            <h3> Operation Classification </h3>
            <div style={{margin: '10px'}}>
            <ul>
              {
                this.state?.safer_page?.operation_classification.map(
                  (label) => <li className="tag">{label}</li>)
              }
              </ul>
            </div>

            <h3> Cargo Carried </h3>
            <div style={{margin: '10px'}}>
            <ul>
            {
              this.state?.safer_page?.cargo_carried.map(
                (label) => <li className="tag">{label}</li>)
            }
            </ul>
            </div>
            <EditCarrierInfoDialog
              showEditInfo={this.state.showEditInfo}
              openDialog={this.showEditInfo}
              closeDialog={this.hideEditInfo}
              legal_name={this.state?.safer_page?.legal_name}
              dot_number={this.state?.safer_page?.dot_number}
              slug={this.state?.safer_page?.slug}
              reloadCarrierData={this.getCarrierData}
            />
            <br></br>
            Need to contact <b>{this.displayName(this.state.safer_page?.legal_name)}</b>?
            <button style={{ marginTop: '10px', height: '50px'}} onClick={this.showSendMessageDialog} className="secondary-button">
             <EmailIcon style={{verticalAlign: 'top'}}/>
              <span style={{ marginLeft: '8px', verticalAlign: 'top', fontSize: '18px'}}>Send message</span>
            </button>
             { this.state.showSendMessageDialog &&
               <SendMessageDialog
                 openDialog={this.showSendMessageDialog}
                 closeDialog={this.hideSendMessageDialog}

                 carrier_name={this.displayName(this.state.safer_page.legal_name)}
                 dot_number={this.state.safer_page.dot_number}
                 slug={this.state.safer_page.slug}
                 reloadCarrierData={this.getCarrierData}
               />
               }
          </div>

        </div>      
    );
  }


  render() {
    var { dot_number, slug } = this.state;

    var aboutSection = `${this.displayName(this.state?.name)} has no about section`
    var carrier_operation = this.state?.safer_page?.carrier_operation
    var operation_classification = this.state?.safer_page?.operation_classification
    var cargo_carried = this.state?.safer_page?.cargo_carried


    if(operation_classification?.length > 0 && cargo_carried?.length > 0) {
        aboutSection =   `${this.displayName(this.state?.name)}'s operation classification is ${operation_classification.join(', ')}. They have a demonstrated history of carrying cargo types ${cargo_carried.join(', ')}`

          if (carrier_operation?.length > 0) {
            aboutSection += ` accross carrier operations ${this.state?.safer_page?.carrier_operation.join(', ')}`
          }
    }

    aboutSection += "."

    if (this.state.status && this.state.status === 'inactive') {
      return (<RecordInactive dot_number={this.state.dot_number}/>);
    }

    // we display the first card only in adds
    if (this.props.renderFirstCardOnly) {
      return this.renderCarrierFirstCard(aboutSection, this.props.renderFirstCardOnly);
    }

    return (
      <>
      <Helmet>
            <meta charSet="utf-8" />
            <title>{this.displayName(this.state?.name)}</title>
            <meta name="description" content={`${this.displayName(this.state?.name)}, DOT ${dot_number} has been reviewed by... Leave a review now.`} />
            <link rel="canonical" href={`https://carriesfind.com/carriers/${dot_number}/business/${slug}`} />
      </Helmet>
      <div className="carrier-page" style={{display: 'flex'}}>
          {this.renderCarrierFirstCard(aboutSection)}
        <div className="second-card">
          <div className="card">
            <ReactMapGL
                mapboxApiAccessToken={mapboxApiKey}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                {...mapStyle}
                {...this.state.viewport}
                onViewportChange={(viewport) => this.setState(() => viewport)}
            >
            { this.state?.address && this.state?.address?.longitude &&
              <Marker key={"esgfeg"} longitude={this.state?.address.longitude} latitude={this.state?.address.latitude} >
                <img width="35px" src={locationPin} />
              </Marker>
            }
            </ReactMapGL>

            <p style={{marginLeft: '10px', marginTop: '20px'}}>{this.state.address?.address} </p>
        </div>

        <div className="claim-section" >
          {!this.state.isEditable && (
            <div style={{marginTop: '50px'}}>
                <div onClick={this.showClaimDialog}>Is {this.state?.safer_page?.dot_number} your DOT number? <b style={{cursor: 'pointer'}}><u>Claim this page now</u></b></div>
                <div>{
                  this.state.messages_count > 0 ?
                  <p >You have <span style={{backgroundColor: 'red', color: 'white', borderRadius: '2px', padding: '3px'}}>{this.state.messages_count}</span> unread message(s). Claim this page to read them.</p>
                  :
                  <p >Claiming a page allows you to edit information, and receive inquiries.</p>

                }
                </div>

                <ClaimDialog
                  showClaimDialog={this.state.showClaimDialog}
                  openDialog={this.showClaimDialog}
                  closeDialog={this.hideClaimDialog}
                  legal_name={this.state?.safer_page?.legal_name}
                  dot_number={this.state?.safer_page?.dot_number}
                  phone_number={this.state?.safer_page?.phone_number}
                  reloadCarrierData={this.getCarrierData}
                  slug={this.state?.safer_page?.slug}
                />
                </div>
            )
            }


            <div>Not your DOT? Find it here:
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'start', marginTop: '10px'}}>
            <input
              type="text"
              style={{fontSize: '20px', height: '37px', width: '50%', minWidth: '120px'}}
              placeholder={"DOT Number"}
              onChange={(e) => {
                const val = e.target.value;
                this.setState({dot_number_input: val})
              }}
            />
            <button className="dot-research-area-button" style={{verticalAlign: 'bottom'}} onClick={this.searchArea}>Search</button>
              </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="carrier-page" style={{display: 'flex'}}> 
        <div id="review-section" className="card first-card" >
          <div style={{padding: '30px'}}>
            <h2>{this.displayName(this.state?.name)} Reviews</h2>
            <button onClick={this.showReviewDialog} style={{height: '50px'}} className="secondary-button">
              <img width="20px" src={writeReviewStar} style={{verticalAlign: 'top'}}></img>
              <span style={{ marginLeft: '8px', verticalAlign: 'top', fontSize: '18px'}}>Write a review</span>
            </button>
             { this.state.showReviewAdd &&
               <WriteAReviewDialog
                openDialog={this.showReviewDialog}
                closeDialog={this.hideReviewDialog}
                carrier_name={this.displayName(this.state?.safer_page?.legal_name)}
                dot_number={this.state?.safer_page?.dot_number}
                slug={this.state?.safer_page?.slug}
                reloadCarrierData={this.getCarrierData}
               /> }
             <Reviews reviews={this.state.reviews} />
          </div>
        </div>
        
          <div id="adds" style={{backgroundColor: '#F7F7F7'}} className="card second-card" >
            <div style={{ padding: '30px' }}>
              <Adds carrier_id={this.state.id} displayOnCarrierPage={true} itemPerPage={2} isEditable={true}></Adds>
          </div>
          </div>          
        </div>

        { this.state.isEditable && (
          <div className="card">
            <div style={{padding: '30px'}}>
              <h2>{this.displayName(this.state?.name)} Message Inbox</h2>
               <Messages messages={this.state.messages} />
            </div>
          </div>
        )}

      </>

    )
  }
}

export default withRouter(Carrier);
