import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';


export default function PaginatedBar({ itemsPerPage, itemsCount, itemOffset, setItemOffset }) {
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  
  
  return (
    <>    
      <ReactPaginate
        className="pagination"
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageLinkClassName={"pagination_number"}
        activeLinkClassName={"pagination_number_selected"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

