/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link } from "react-router-dom";

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";

import StarRating from './components/StarRating';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Reviews from './Reviews';
import Stars from './Stars';
import ClaimDialog from './ClaimDialog';
import SendMessageDialog from './SendMessageDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EmailIcon from '@material-ui/icons/Email';
import RecordInactive from './RecordInactive';
import backgroundImageAbout from './assets/carriesfind-profile-placeholder-1.png';
import selectedStar from './assets/Review-star-selected.png';
import unselectedStar from './assets/Review-star-unselected.png';
import './Carriers.css';
import ReactMapGL, {Marker} from 'react-map-gl';
import Geocoder from 'react-mapbox-gl-geocoder';
import locationPin from './assets/Location-pin-1-selected.png';
import writeReviewStar from './assets/write-review-star.png';
import editIcon from './assets/edit-icon@2x.png';
import {API_HOST_NAME} from './Environment.js';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import Messages from './Messages';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Carrier from './Carrier.js';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import FlagContent from './FlagContent.js';
import ArchiveButton from './ArchiveButton.js';

class Add extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      subject: "",
      body: "",
      city: "",
      state: "",
      documents: [],
      carrier: {},
      safer_page: {},
      isEditable: false
    }
  }

  componentDidMount() {
    this.getAdd(this.props.match.params['add_id']);
  }

  getAdd = (id) => {
    fetch(`${API_HOST_NAME()}/adds/${id}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState((state, props) => {
          debugger
          return json
        }, () => {
          this.setState(() => {
            debugger
            return { isLoading: false, isEditable: this.props.user?.id === this.state.carrier?.user_id }
            })
        });
      });
  }


  render = () => {


    return (
      <div class="carrier-page" style={{display: 'flex'}}>
        <div className="card" style={{ position: 'relative', maxWidth: '800px', height: '400px', width: '100%', paddingTop: '10px' }}> 

          <div style={{ margin: '20px', height: '100%' }} >          
          {this.state.archived && <p>This ad is not live anymore</p>}

            <h2 style={{marginBottom: '5px'}}>{this.state.subject}</h2>  
            <label style={{ fontSize: '12px' }}>Posted by {this.state.safer_page.legal_name}
              <br></br>
              {this.state.state}, {new Date(this.state.created_at).toLocaleDateString()}
            </label>
            <br></br>
            <br></br>  
          <p> {this.state.body}</p>          
            
            <PhotoProvider>
            {this.state.documents.map((document, index) => { return (
                <PhotoView src={document}>
                  <img src={this.state.thumbnails[index]} width="100" alt="" />
                </PhotoView>
            );
            })}
            </PhotoProvider>
            
            <p style={{ position: 'absolute', bottom:0, padding: 0, margin: '2px' }}>  
              <FlagContent add={this.state} reloadAd={() => { this.getAdd(this.state.id) } }></FlagContent>
            </p>            
            
            {this.state.isEditable && <p style={{ position: 'absolute', right: '15px', bottom:0, padding: 0, margin: '2px' }}><ArchiveButton add={this.state} reloadAd={() => { this.getAdd(this.state.id) } }></ArchiveButton></p>}
          </div>
                   
      </div>       

        {this.state.safer_page?.dot_number && <Carrier  showSignupDialog={this.props.showSignupDialog} dot_number={this.state.safer_page.dot_number} slug={this.state.safer_page.slug} renderFirstCardOnly={true}>
            
        </Carrier>}
      </div>);
  } 

  
}

export default withRouter(Add);
