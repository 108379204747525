import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Stars from './Stars';
import mapboxgl from 'mapbox-gl';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import placeholderOne from './assets/carriesfind-profile-placeholder-1@3x.png'
import placeholderTwo from './assets/carriesfind-profile-placeholder-2@3x.png'
import placeholderThree from './assets/carriesfind-profile-placeholder-3@3x.png'

class SearchResultCard extends React.Component {
  // <p> {this.props.carrier.address.address} </p>
  //
  // <p> {this.props.carrier.safer_page.operation_classification} </p>
  isLetter = (str) => {
    return str.length === 1 && str.match(/[a-z]/i);
  }
  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  diplayName = (name) => {
    var newName = []
    name.split(" ").forEach((part) => {
      var format = false;
      for (var i = 0; i < part.length - 1 ; i++) {
        if (part.toLowerCase() == 'llc') {
          part = 'LLC';
          break;
        }
        const c = part.charAt(i);
        const d = part.charAt(i + 1);
        if (this.isLetter(c) && this.isLetter(d)) {
          format = true;
          break
        }
      }
      if (format) {
        newName.push(this.capitalize(part.toLowerCase()))
      } else {
        newName.push(part)
      }
    })

    return newName.join(" ")
  }
  //            <p> {this.props.carrier.safer_page.physical_address}</p>

  render() {
    
    return (
      <div className="box">
        <div className="innerBox">
          <img src={placeholderOne} ></img>
          <div style={{marginLeft: '10px', verticalAlign: 'top'}}>
            <h2 className="card-text" style={{marginTop: 0}}> {this.diplayName(this.props.carrier.name)} </h2>
            <Stars numberOfStars={this.props.carrier?.stars || 0} />
            <div style={{marginBottom: '10px', marginTop: '20px'}}>
              {
                (this.props.carrier.safer_page.carrier_operation.concat(
                  this.props.carrier.safer_page.operation_classification)).map(
                  (label) => <label className="tag">{label}</label>)
              }
            </div>
            <Link to={`carriers/${this.props.carrier.safer_page.dot_number}/business/${this.props.carrier.safer_page.slug}`} >More Details</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchResultCard;
