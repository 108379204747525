// environment variable doesn't work with the stylist admin
export const API_HOST_NAME = () => {
  if (window.location.hostname.includes("localhost")) {
    //return "https://api.carriesfind.com";
    return "http://localhost:3000";
  } else {

    if (window.location.protocol == "http:") {
      window.location = "https://" + window.location.hostname;
    }

    return "https://api.carriesfind.com";
  }
};

export const APP_HOST_NAME = () => {
  if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("staging")
  ) {
    return "http://" + window.location.hostname + ":3001";
  } else {
    return "https://" + window.location.hostname;
  }
};
