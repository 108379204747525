/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter,   Link} from "react-router-dom";

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";
import FlagContent from './FlagContent';


class AddPreview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    }
  }


  render = () => {
    return (
    <div style={{maxWidth: '400px', width: '100%', paddingTop: '10px'}}> 
    <div className="box" style={{height: '225px'}} >  
      <div className="innerbox" style={{margin: '15px'}} >
            <h2 style={{marginBottom: 0}}>{this.props.add.subject}</h2>       
            <label style={{ fontSize: '12px', paddingBottom: '5px' }}>Posted by {this.props.add.safer_page.legal_name}
            <br></br>
             {this.props.add.state}, {new Date(this.props.add.created_at).toLocaleDateString()}
            </label>
            <br></br>

            {this.props.add.thumbnails.slice(0, 1).map((thumbnail) => { return (<img style={{ float: 'right', width: '80px', height: '80px', padding: '10px' }} src={thumbnail} />) })}
            <p> {this.props.add.body.substring(0, 100) + "..."}</p>
            
            <p style={{ float: 'left', position: 'absolute', left: 10, bottom: 0, padding: 0, margin: '2px' }}>  

              <FlagContent showSignupDialog={ this.props.showSignupDialog} add={this.props.add} reloadAd={this.props.reloadAd}></FlagContent>
            </p>


            <Link style={{float: 'right', position: 'absolute', right: 10, bottom: 10}} to={`/adds/${this.props.add.id}`}>Show more</Link>&nbsp;
    </div></div></div>);
  } 

  
}

export default withRouter(AddPreview);
