import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { API_HOST_NAME } from "./Environment.js";

import logo from './assets/carriesfind-logo-only-white.png';
import './App.css';

import SearchResults from './SearchResults';
import SearchPage from './SearchPage';
import Carrier from './Carrier';
import SignupDialog from './SignupDialog';
import Geocoder from 'react-mapbox-gl-geocoder';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Button } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

var imageStyle = {
  width: '30px',
  height: '30px',
  padding: 0,
  margin: 0

}


var buttonStyle = {
  width: '40px',
  height: '40px',
  padding: 0,
  margin: 0,
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px'

}


var selectedStyle = {
  borderTop: 1,
  borderColor: 'blue'
}


function BottomBar({showSignupDialog}) {

  var goToProfile = () => {
    if (!localStorage.getItem('uid')) {
      showSignupDialog();
    } else {
      window.open('/profile');
    }
  };
  
  return(
    <div className="bottom-bar" >

      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', margin: "0px 20px" }}>
        <Link style={buttonStyle} to="/adds"><QuestionAnswerIcon/></Link>
        <p style={{margin: 0, padding: 0, fontSize: 10}}>Ad Board</p>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', margin: "0px 20px" }}>
      <Link style={buttonStyle} to="/search"><SearchIcon/></Link>
      <p style={{margin: 0, padding: 0, fontSize: 10}}>Search</p>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', margin: "0px 20px" }}>
        <Button onClick={() => { goToProfile() } } style={buttonStyle} to="/profile"><AccountCircleIcon></AccountCircleIcon></Button>
      <p style={{margin: 0, padding: 0, fontSize: 10}}>Account</p>
      </div>

    </div>
  );
}


export default BottomBar;
