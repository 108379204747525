/* global woopra */
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import StarRating from './components/StarRating';
import {isMobile} from 'react-device-detect';
import ReCAPTCHA from "react-google-recaptcha";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClaimDialog from './ClaimDialog.js';


class PostAddDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
      email: null,
      state: '',
      files: [],
      carriers: [],
      carrier_id: "",
      showClaimDialog: false,
      dot_number: null,

      safer_page: {}
    }
  }

  componentDidMount() {
    this.preCreate();
  }

  preCreate = () => {
    fetch(`${API_HOST_NAME()}/adds/pre-create`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'uid': localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        'expiry': localStorage.getItem('expiry'),
        'client': localStorage.getItem('client')
      }}).then(response => {
      return response.json()
    })
      .then(json => {
        if (json.error_message) {
          return
        }
        
        this.setState({ carriers: json, carrier_id: json[0]?.id });
      });
  }

  getSaferPage = () => {
    
    fetch(`${API_HOST_NAME()}/safer_pages/${this.state.dot_number}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'uid': localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        'expiry': localStorage.getItem('expiry'),
        'client': localStorage.getItem('client')
      }
    })
      .then(response => {
      return response.json()
    })
      .then(json => {
        if (json.error_message) {
          return
        }
        
        this.setState({ safer_page: json['safer_page'], showClaimDialog: true });
      });
  }

  submitAdd = () => {
    const message = {add: {
        body: this.state.body,
        subject: this.state.subject,
        city: this.state.city,
        state: this.state.state,
        carrier_id: this.state.carrier_id
      }
    }

    fetch(`${API_HOST_NAME()}/adds`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(message),
    })
      .then(response => {
        return response.json()})
      .then(json => {
        if (json.error_message) {
           return
        }

        var countFileRead = 0;
        if (this.state.files.length > 0) {
          this.state.files.forEach((e) => {
            var reader = new FileReader();
            reader.onload = () => {
              var arrayBuffer = reader.result
              var array = new Uint8Array(arrayBuffer)
              var binaryString = btoa(array.reduce(function (data, byte) {
                return data + String.fromCharCode(byte);
              }, ''));
        
              fetch(`${API_HOST_NAME()}/adds/${json['id']}/save_document`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json',
                'uid': localStorage.getItem('uid'),
                'access-token': localStorage.getItem('access-token'),
                'expiry': localStorage.getItem('expiry'),
                'client': localStorage.getItem('client')
              },
              body: JSON.stringify({bytes: binaryString, title: reader.filename }),
              }).then((response) => {
                countFileRead++;
                if (countFileRead == this.state.files.length) {
                  
                  this.props.closeDialog();
                  this.props.reloadAdds();
                }
              });
            }

            reader.filename = e.name;
            reader.readAsArrayBuffer(e)
          });
        } else {
          
          this.props.closeDialog();
          this.props.reloadAdds();
        }
        });
  }
  handleChangeSelectedCarrier = (e) => {
    this.setState(() => {return {carrier_id: e.target.value}});
  }

  handleChangeState = (e) => {
    this.setState(() => {return {state: e.target.value}});
  }

  handleChangeSubject = (event) => {
    const message = event.target.value;
    this.setState(() => { return { subject: message } });
  };

  handleChangeDOTNumber = (event) => {
    const message = event.target.value;
    this.setState(() => { return { dot_number: message } });
  };

  handleChangeBody = (event) => {
    const email = event.target.value;
    this.setState(() => { return { body: email } });
  };



  render() {
    if (this.state.showClaimDialog) {
      
      return (<ClaimDialog
        showClaimDialog={this.state.showClaimDialog}
        openDialog={() => this.setState({ showClaimDialog: true })}
        closeDialog={() => this.setState({ showClaimDialog: false })}
        legal_name={this.state.safer_page.legal_name}
        dot_number={this.state.safer_page.dot_number}
        phone_number={this.state.safer_page.phone_number}
        slug={this.state.safer_page.slug}
      />);
    }

    if (this.state.carriers.length === 0) {
      return ( <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        open={this.props.openDialog}
        onClose={this.props.closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{padding: 0, margin: 0}}>
      </DialogTitle>
      
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="medium-text" style={{marginBottom: '20px', minWidth: '300px'}}>Submit a public ad</p>
            <p> What is your DOT number?</p>
            <TextField
            label="DOT Number"
            variant="filled"
            style={{width: '100%', marginTop: '25px', marginBottom: '10px'}}
            value={this.state.dot_number} onChange={this.handleChangeDOTNumber} />

            <br></br>
          
            {!isMobile && (
              <>
                <Button style={{ float: 'right', marginRight: '30px', marginTop: '30px', marginBottom: '30px', color: 'white', backgroundColor: '#00A2AA' }} variant="filled" onClick={this.getSaferPage}>Submit</Button>
                <Button style={{ float: 'right', marginRight: '30px', marginTop: '30px', marginBottom: '30px' }} onClick={this.props.closeDialog}>Cancel</Button>
                
                </>)
            }
          </DialogContentText>
          { isMobile && (
            <DialogActions>
              <Button onClick={() => {
                this.setState(() => {
                  return {error: null}
                });
                this.props.closeDialog();
              }} color="primary">
              Cancel
              </Button>
              <Button style={{color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.getSaferPage}>Submit</Button>
            </DialogActions>
        )}
  

        </DialogContent>
      </Dialog>);
    } 

    
    return (
        <Dialog
          fullScreen={isMobile}
          maxWidth="md"
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{padding: 0, margin: 0}}>
        </DialogTitle>
        
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p className="medium-text" style={{marginBottom: '20px'}}>Submit a public ad</p>
              <p> You can ask about renting your truck, finding employee, advice...anything you need! Make sure to follow submission guidelines.</p>
              
            <Select
              style={{width: '500px'}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.carriers[0].id}
              onChange={this.handleChangeSelectedCarrier}
           > 
              {this.state.carriers.map((carrier) => { return (<MenuItem value={carrier.id} >{carrier.name}</MenuItem>); })}
            </Select>

              <TextField
              label="Subject (Required)"
              variant="filled"
              placeholder="Write a short descriptive subject."
              style={{width: '100%', marginTop: '25px', marginBottom: '10px'}}
              value={this.state.email} onChange={this.handleChangeSubject} />

              <TextField
              style={{width: '100%', marginTop: '20px'}}
              multiline
              rows='4'
              label="Body (Required)"
              variant="filled"
              placeholder="Write your body here..."
              value={this.state.body} onChange={this.handleChangeBody} />

        <InputLabel style={{marginTop: '20px'}} id="demo-simple-select-label">State</InputLabel>
            <Select
              style={{width: '150px'}}
              labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.state}
          onChange={this.handleChangeState}
        > 
            <MenuItem value={'Alaska'}>Alaska</MenuItem>
            <MenuItem value={'Alabama'}>Alabama</MenuItem>
            <MenuItem value={'Arkansas'}>Arkansas</MenuItem>
            <MenuItem value={'American Samoa'}>American Samoa</MenuItem>
            <MenuItem value={'Arizona'}>Arizona</MenuItem>
            <MenuItem value={'California'}>California</MenuItem>
            <MenuItem value={'Colorado'}>Colorado</MenuItem>
            <MenuItem value={'Connecticut'}>Connecticut</MenuItem>
            <MenuItem value={'District of Columbia'}>District of Columbia</MenuItem>
            <MenuItem value={'Delaware'}>Delaware</MenuItem>
            <MenuItem value={'Florida'}>Florida</MenuItem>
            <MenuItem value={'Georgia'}>Georgia</MenuItem>
            <MenuItem value={'Guam'}>Guam</MenuItem>
            <MenuItem value={'Hawaii'}>Hawaii</MenuItem>
            <MenuItem value={'Iowa'}>Iowa</MenuItem>
            <MenuItem value={'Idaho'}>Idaho</MenuItem>
            <MenuItem value={'Illinois'}>Illinois</MenuItem>
            <MenuItem value={'Indiana'}>Indiana</MenuItem>
            <MenuItem value={'Kansas'}>Kansas</MenuItem>
            <MenuItem value={'Kentucky'}>Kentucky</MenuItem>
            <MenuItem value={'Louisiana'}>Louisiana</MenuItem>
            <MenuItem value={'Massachusetts'}>Massachusetts</MenuItem>
            <MenuItem value={'Maryland'}>Maryland</MenuItem>
            <MenuItem value={'Maine'}>Maine</MenuItem>
            <MenuItem value={'Michigan'}>Michigan</MenuItem>
            <MenuItem value={'Minnesota'}>Minnesota</MenuItem>
            <MenuItem value={'Missouri'}>Missouri</MenuItem>
            <MenuItem value={'Mississippi'}>Mississippi</MenuItem>
            <MenuItem value={'Montana'}>Montana</MenuItem>
            <MenuItem value={'North Carolina'}>North Carolina</MenuItem>
            <MenuItem value={'North Dakota'}>North Dakota</MenuItem>
            <MenuItem value={'Nebraska'}>Nebraska</MenuItem>
            <MenuItem value={'New Hampshire'}>New Hampshire</MenuItem>
            <MenuItem value={'New Jersey'}>New Jersey</MenuItem>
            <MenuItem value={'New Mexico'}>New Mexico</MenuItem>
            <MenuItem value={'Nevada'}>Nevada</MenuItem>
            <MenuItem value={'New York'}>New York</MenuItem>
            <MenuItem value={'Ohio'}>Ohio</MenuItem>
            <MenuItem value={'Oklahoma'}>Oklahoma</MenuItem>
            <MenuItem value={'Oregon'}>Oregon</MenuItem>
            <MenuItem value={'Pennsylvania'}>Pennsylvania</MenuItem>
            <MenuItem value={'Puerto Rico'}>Puerto Rico</MenuItem>
            <MenuItem value={'Rhode Island'}>Rhode Island</MenuItem>
            <MenuItem value={'South Carolina'}>South Carolina</MenuItem>
            <MenuItem value={'South Dakota'}>South Dakota</MenuItem>
            <MenuItem value={'Tennessee'}>Tennessee</MenuItem>
            <MenuItem value={'Texas'}>Texas</MenuItem>
            <MenuItem value={'Utah'}>Utah</MenuItem>
            <MenuItem value={'Virginia'}>Virginia</MenuItem>
            <MenuItem value={'Virgin Islands'}>Virgin Islands</MenuItem>
            <MenuItem value={'Vermont'}>Vermont</MenuItem>
            <MenuItem value={'Washington'}>Washington</MenuItem>
            <MenuItem value={'Wisconsin'}>Wisconsin</MenuItem>
            <MenuItem value={'West Virginia'}>West Virginia</MenuItem>
            <MenuItem value={'Wyoming'}>Wyoming</MenuItem>
        </Select>


              <br></br>
              {!isMobile && (<Button style={{float: 'right', marginRight: '30px', marginTop: '30px', marginBottom: '30px', color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitAdd}>Post Ad</Button>)}
            </DialogContentText>
            { isMobile && (
              <DialogActions>
                <Button onClick={() => {
                  this.setState(() => {
                    return {error: null}
                  });
                  this.props.closeDialog()
                }} color="primary">
                Cancel
                </Button>
                <Button style={{color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitAdd}>Post Ad</Button>
              </DialogActions>
          )}
          <Input type="file" multiple name="file(s)" inputProps={{ accept: 'image/jpeg,image/gif,image/png', multiple: true }} onChange={(event) => {
            
            this.setState({ files: [...event.target.files] });
            }}/>

          </DialogContent>
        </Dialog>
      );
  }
}

export default PostAddDialog;
