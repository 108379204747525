import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { API_HOST_NAME } from "./Environment.js";

import profile from './assets/myprofile-icon@3x.png';
import logo from './assets/carriesfind-logo-only-white.png';
import './App.css';

import SearchResults from './SearchResults';
import SearchPage from './SearchPage';
import Carrier from './Carrier';
import SignupDialog from './SignupDialog';
import Geocoder from 'react-mapbox-gl-geocoder';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

const mapStyle = {
    width: '100%',
}
const params = {
    country: "us",
}

const mapboxApiKey ='pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

// { this.props.safer_pages.map((safer_page) => {
//   return (
//     <span>
//       <Link to={`/carriers/${safer_page.dot_number}/business/${safer_page.slug}`}>
//         {safer_page.legal_name}
//       </Link>
//     </span>);
//   })
// }

class LoginBar extends React.Component {

  componentDidMount() {

    this.props.ping()
  }

  render() {
    return (
    <div id="loginbar" style={{width: '100%', height: '60px', background: '#1A71D8'}}>
      <Link to="/"><img src={logo} alt="Logo" style={{width: '35px', marginTop: '7px',marginLeft: '21px'}} /></Link>

      {
        
        <h1 style={{display: 'inline', color: 'white', verticalAlign: 'super', margin: 'auto', fontSize: '36px', marginLeft: '20px'}}>Carrie's Find</h1>
        
      }
      <span style={{float: 'right',  marginTop: '20px', marginRight: '10px'}}>
      <Link className="cf-mapbox-map" style={{margin: '0 30px 0 0', padding: 0, color: 'white'}} to="/adds">Ad Board</Link>
      <Link className="cf-mapbox-map" style={{margin: '0 30px 0 0', padding: 0, color: 'white'}} to="/">Search</Link>
      <Link className="cf-mapbox-map" style={{margin: '0 30px 0 0', padding: 0, color: 'white'}} to="/about">About</Link>
      <Link className="cf-mapbox-map" style={{margin: '0 30px 0 0', padding: 0, color: 'white'}} to="/how-to">How To</Link>

      { this.props.user ?
        <Link className="cf-mapbox-map" to="/profile"><img alt="profile" src={profile} width="20px" style={{verticalAlign: 'bottom'}}/></Link>
        :
        <>
        <span className='topBar-button cf-mapbox-map' style={{padding: '15px', marginRight: '20px'}} onClick={this.props.showSigninDialog}> login </span>
        <span className='topBar-button-filled cf-mapbox-map' style={{padding: '15px'}}
        onClick={this.props.showSignupDialog}> Signup </span>
        </>
          }
          
          

      </span>
    </div>);
  }
}

export default LoginBar;
