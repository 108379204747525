/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";

import AddIcon from '@material-ui/icons/Add';
import PostAddDialog from './PostAddDialog.js';
import Add from './Adds.js';
import AddPreview from './AddPreview.js';
import StarRating from './components/StarRating';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Reviews from './Reviews';
import Stars from './Stars';
import ClaimDialog from './ClaimDialog';
import SendMessageDialog from './SendMessageDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EmailIcon from '@material-ui/icons/Email';
import RecordInactive from './RecordInactive';
import backgroundImageAbout from './assets/carriesfind-profile-placeholder-1.png';
import selectedStar from './assets/Review-star-selected.png';
import unselectedStar from './assets/Review-star-unselected.png';
import './Carriers.css';
import ReactMapGL, {Marker} from 'react-map-gl';
import Geocoder from 'react-mapbox-gl-geocoder';
import locationPin from './assets/Location-pin-1-selected.png';
import writeReviewStar from './assets/write-review-star.png';
import editIcon from './assets/edit-icon@2x.png';
import {API_HOST_NAME} from './Environment.js';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import Messages from './Messages';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MenuDropDown from './components/MenuDropDown.js';
import DropDown from '@material-ui/core/MenuList';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReactPaginate from 'react-paginate';
import PaginatedBar from './PaginatedBar.js';

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";



class Adds extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      adds: [],
      state: "All States",
      addsOffset: 0,
      adds_count: 0,
      searchQuery: "",
    }
  }


  componentDidMount() {
    if (!this.props.displayOnCarrierPage) {
      this.getAdds();
    }    
  }

  componentDidUpdate(prevProps, prevState) {
    debugger
    if (this.props.displayOnCarrierPage && prevProps.carrier_id !== this.props.carrier_id) {
      this.getAdds();  
    }
  }

  getAdds = () => {
    var queryString = new URLSearchParams();
    var filters = {}
    if (this.props.carrier_id) {
      filters['carrier_id'] = this.props.carrier_id
    }
    if (this.state.state !== "All States") {
      filters['state'] = this.state.state
    }
    if (Object.keys(filters).length !== 0) {
      queryString.append('filter', JSON.stringify(filters))
    }

    var range = {}

    if (this.state.addsOffset != null) {
      range['offset'] = this.state.addsOffset;
    }
    if (this.props.itemPerPage != null) {
      range['limit'] = this.props.itemPerPage
    }
    if (Object.keys(range).length === 0) {
      queryString.append('range', JSON.stringify(range));
    }
    
    if (this.state.searchQuery !== "") {
      queryString.append('q', this.state.searchQuery)
    }
    debugger
    
    
    fetch(`${API_HOST_NAME()}/adds?${queryString.toString()}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState( (state, props) => {
          return {...json, isLoading: false}});        
      });
  }


  showPostAddDialog = () => {
    
    if (!this.props.showSignupDialog()) {

      this.setState(() => {return {showPostAddDialog: true}})
    }
  }
  hidePostAddDialog = () => {
    this.setState(() => {return {showPostAddDialog: false}})
  }

  handleChangeState = (e) => {
    this.setState(() => {return {state: e.target.value}}, this.getAdds);
  }

  setAddsOffset = (offset) => {
    this.setState(() => {return {addsOffset: offset}}, this.getAdds)
  }

  render = () => {
    return (
        
      <>
    <div style={{margin: '13px'}}> 
          { !this.props.displayOnCarrierPage ? <>
            <Button class="post-ad-button-desktop" style={{ width: '200px' }} onClick={() => this.showPostAddDialog()} ><AddIcon style={{verticalAlign: 'bottom'}}></AddIcon>Post an ad to the board</Button>
            <Button class="post-ad-button-mobile" onClick={() => this.showPostAddDialog()} ><AddIcon style={{ fontSize: '90px', width: '60px', height: '60px', verticalAlign: 'bottom' }}></AddIcon></Button></>
          : <h2>Active Ads</h2>  
          }      
    <br></br>
      <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', alignItems: 'baseline'}}>
          
 
            {!this.props.displayOnCarrierPage && 
              <>
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <TextField
              label="Search all ads"
                  style={{ width: '100%', maxWidth: '250px' }}
                  value={this.state.searchQuery}
              onChange={(e)=>{this.setState({searchQuery: e.target.value})}}    
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }} 
            />
              <Button class="dot-research-area-button" onClick={this.getAdds}>Search</Button>
            </div>
            <div style={{ display: 'flex', flexFlow: 'row wrap', textAlign: 'center', alignItems: 'end', marginBottom: '10px', marginLeft: '30px', marginTop: '20px'}}>Show only
              <FormControl style={{ marginLeft: '20px', width: '150px', verticalAlign: 'baseline' }}>          
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.state}
                  onChange={this.handleChangeState}> 
                    <MenuItem value={'All States'}>All States</MenuItem>                
                    <MenuItem value={'Alaska'}>Alaska</MenuItem>
                    <MenuItem value={'Alabama'}>Alabama</MenuItem>
                    <MenuItem value={'Arkansas'}>Arkansas</MenuItem>
                    <MenuItem value={'American Samoa'}>American Samoa</MenuItem>
                    <MenuItem value={'Arizona'}>Arizona</MenuItem>
                    <MenuItem value={'California'}>California</MenuItem>
                    <MenuItem value={'Colorado'}>Colorado</MenuItem>
                    <MenuItem value={'Connecticut'}>Connecticut</MenuItem>
                    <MenuItem value={'District of Columbia'}>District of Columbia</MenuItem>
                    <MenuItem value={'Delaware'}>Delaware</MenuItem>
                    <MenuItem value={'Florida'}>Florida</MenuItem>
                    <MenuItem value={'Georgia'}>Georgia</MenuItem>
                    <MenuItem value={'Guam'}>Guam</MenuItem>
                    <MenuItem value={'Hawaii'}>Hawaii</MenuItem>
                    <MenuItem value={'Iowa'}>Iowa</MenuItem>
                    <MenuItem value={'Idaho'}>Idaho</MenuItem>
                    <MenuItem value={'Illinois'}>Illinois</MenuItem>
                    <MenuItem value={'Indiana'}>Indiana</MenuItem>
                    <MenuItem value={'Kansas'}>Kansas</MenuItem>
                    <MenuItem value={'Kentucky'}>Kentucky</MenuItem>
                    <MenuItem value={'Louisiana'}>Louisiana</MenuItem>
                    <MenuItem value={'Massachusetts'}>Massachusetts</MenuItem>
                    <MenuItem value={'Maryland'}>Maryland</MenuItem>
                    <MenuItem value={'Maine'}>Maine</MenuItem>
                    <MenuItem value={'Michigan'}>Michigan</MenuItem>
                    <MenuItem value={'Minnesota'}>Minnesota</MenuItem>
                    <MenuItem value={'Missouri'}>Missouri</MenuItem>
                    <MenuItem value={'Mississippi'}>Mississippi</MenuItem>
                    <MenuItem value={'Montana'}>Montana</MenuItem>
                    <MenuItem value={'North Carolina'}>North Carolina</MenuItem>
                    <MenuItem value={'North Dakota'}>North Dakota</MenuItem>
                    <MenuItem value={'Nebraska'}>Nebraska</MenuItem>
                    <MenuItem value={'New Hampshire'}>New Hampshire</MenuItem>
                    <MenuItem value={'New Jersey'}>New Jersey</MenuItem>
                    <MenuItem value={'New Mexico'}>New Mexico</MenuItem>
                    <MenuItem value={'Nevada'}>Nevada</MenuItem>
                    <MenuItem value={'New York'}>New York</MenuItem>
                    <MenuItem value={'Ohio'}>Ohio</MenuItem>
                    <MenuItem value={'Oklahoma'}>Oklahoma</MenuItem>
                    <MenuItem value={'Oregon'}>Oregon</MenuItem>
                    <MenuItem value={'Pennsylvania'}>Pennsylvania</MenuItem>
                    <MenuItem value={'Puerto Rico'}>Puerto Rico</MenuItem>
                    <MenuItem value={'Rhode Island'}>Rhode Island</MenuItem>
                    <MenuItem value={'South Carolina'}>South Carolina</MenuItem>
                    <MenuItem value={'South Dakota'}>South Dakota</MenuItem>
                    <MenuItem value={'Tennessee'}>Tennessee</MenuItem>
                    <MenuItem value={'Texas'}>Texas</MenuItem>
                    <MenuItem value={'Utah'}>Utah</MenuItem>
                    <MenuItem value={'Virginia'}>Virginia</MenuItem>
                    <MenuItem value={'Virgin Islands'}>Virgin Islands</MenuItem>
                    <MenuItem value={'Vermont'}>Vermont</MenuItem>
                    <MenuItem value={'Washington'}>Washington</MenuItem>
                    <MenuItem value={'Wisconsin'}>Wisconsin</MenuItem>
                    <MenuItem value={'West Virginia'}>West Virginia</MenuItem>
                    <MenuItem value={'Wyoming'}>Wyoming</MenuItem>
               </Select>
            </FormControl> 
            
                <div style={{ marginLeft: '15px', marginRight: '20px' }}><b>{this.state.adds_count} Results</b></div>
                 </div>
                 </>
}            
 </div>
        <div class="adds_grid">
            
    { this.state.showPostAddDialog &&
                 <PostAddDialog
                   openDialog={this.showPostAddDialog}
                   closeDialog={this.hidePostAddDialog}
                   reloadAdds={this.getAdds}
                 />
                 }
            {this.state.adds.map((add) => <AddPreview add={add} reloadAd={this.getAdds} showSignupDialog={this.props.showSignupDialog} /> )} 
          </div>
    <br></br>      
          <PaginatedBar style={{margin: 'auto'}} itemsCount={this.state.adds_count} itemsPerPage={this.props.itemPerPage || 10} itemOffset={this.state.addsOffset} setItemOffset={this.setAddsOffset} ></PaginatedBar>

    </div>
    </>
    );
  } 

}

export default withRouter(Adds);
