/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";
 
import Add from './Add.js';


class AddPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
  }


  render = () => {
    return (
      <div>
        <Add add_id={this.props.match.params.add_id} showSignupDialog={this.props.showSignupDialog} user={this.props.user}></Add>
      </div>
    );
  } 

  
}

export default withRouter(AddPage);
