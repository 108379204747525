/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import {API_HOST_NAME} from './Environment.js';

import SignupDialog from './SignupDialog';
import WriteAReviewDialog from './WriteAReviewDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";
 
import Add from './Add.js';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnArchive from '@material-ui/icons/Unarchive.js';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined.js';

class ArchiveButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    }
  }
  
  archiveAd = () => {
    if (!localStorage.getItem('uid')) {
      this.props.showSignupDialog()
    }
    
    fetch(`${API_HOST_NAME()}/adds/${this.props.add.id}/archive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'uid': localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        'expiry': localStorage.getItem('expiry'),
        'client': localStorage.getItem('client')
      }
    })
      .then(response => response.json())
      .then(json => {
        this.props.reloadAd()
      });
  }


  render = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '20px' }}>
        { this.props.add.archived ?
          <ArchiveIcon  onClick={ this.archiveAd } /> :
          <ArchiveOutlinedIcon  onClick={ this.archiveAd }/>
        }
        {this.props.add.archived ?
          <label style={{fontSize: '10px'}}>Unarchive</label>:
          <label style={{fontSize: '10px'}}>Archive</label> 
        }

      </div>
    );
  } 

  
}

export default withRouter(ArchiveButton);
